import { Component } from 'react'
import isBase64 from 'is-base64';

class ProfileImage extends Component {

  static getThumb = (chat) => {
    if (chat !== undefined) {
      let thumb = chat.thumbProfilePicture;
      if (thumb) {
        if (isBase64(thumb)) {
          return "data:image/jpeg;base64," + thumb;
        } else {
          return thumb;
        }
      }
      return chat.isGroup ? require("../resources/group128.png") : require("../resources/user128.png");
    }
  }
}
export default ProfileImage;