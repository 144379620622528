const ChatAreaStyle = theme => ({

    background: {
        backgroundImage: theme.backgroundImage,
        height: "100vh",
    },

    open: {
        left: theme.drawer.width,
    },

    closed: {
        left: theme.drawerClose.width,
    },

    messageArea: {
        position: "absolute",
        bottom: 70,
        right: 0,
        overflowY: 'auto'
    },

    chatAreaContainer: {
        marginTop: 10,
        width: '100%'
    },

    chatBarOpen: {
        left: theme.drawer.width,
    },

    chatBarClose: {
        left: theme.drawerClose.width,
    },

    chatBar: {
        position: "absolute",
        right: 8,
        marginLeft: 8,
        bottom: 10
    },

    bubbleRow: {
        marginBottom: 10,
        paddingRight: 10,
        paddingLeft: 10
    },

    bubble: {
        borderRadius: theme.roundedCorner.borderRadius,
    },

    fromMe: {
        backgroundColor: theme.bubbleOut.backgroundColor,
        float: 'right'
    },

    fromOther: {
        backgroundColor: theme.bubbleIn.backgroundColor,
        float: 'left'
    },

    progressContainer: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },

    progress: {
        backgroundColor: "rgba(18,18,18,0.5)",
        borderRadius: "50px",
        padding: '4px'
    }

});

export default ChatAreaStyle;