export default class Message {

    constructor(id, isFromMe, fromJid, fromName, fromPhoneNumber, toJid, text, timestamp, state, type, mimeType, fileName, fileSize, duration) {
        this.id = id;
        this.isFromMe = isFromMe;
        this.fromJid = fromJid;
        this.fromName = fromName;
        this.fromPhoneNumber = fromPhoneNumber;
        this.toJid = toJid;
        this.text = text;
        this.timestamp = timestamp;
        this.state = state;
        this.type = type;
        this.mimeType = mimeType;
        this.fileName = fileName;
        this.fileSize = fileSize;
        this.duration = duration;
    }

}