import React, { Component } from 'react';
import { Typography, Grid } from '@mui/material';
import State from '../../../model/State';
import TimeStampFooterStyles from './TimeStampFooterStyles';
import MimeType from '../../../util/MimeType';
import DateUtil from '../../../util/DateUtil';
import { withStyles } from '@mui/styles';


class TimeStampFooter extends Component {

    render() {
        const { message, classes } = this.props;

        const isImage = MimeType.isImage(message.mimeType);
        const isVideo = MimeType.isVideo(message.mimeType);

        return (
            <div className={classes.timeStampFooter}>
                <Grid container direction="row">
                    <Grid item>
                        <Typography variant="caption" component="span" className={isImage || isVideo ? classes.timeStampValueVideo : classes.timeStampValue}>
                            {DateUtil.formatDate(message.timestamp)}
                        </Typography>
                    </Grid>
                    {message.isFromMe &&
                        <Grid item>{State.getIcon(message.state, isImage || isVideo ? classes.statusIconVideo : classes.statusIcon)}</Grid>
                    }
                </Grid>
            </div>
        )
    }
}

export default withStyles(TimeStampFooterStyles)(TimeStampFooter);