import Message from '../../model/Message';
import State from '../../model/State';
import Type from '../../model/Type';

export default class MessageService {

    constructor() {
        this.lastId = 0
        this.messages = {
            "+49151": [this.createFromMessage("+49151", "Hello"),
            this.createToMessage("+49151", "Dennis", "All right 1?"),
            this.createToMessage("+49151", "Dennis", "All right 2?"),
            this.createToMessage("+49151", "Dennis", "All right 3?"),
            this.createToMessage("+49151", "Dennis", "All right 4?"),
            this.createToMessage("+49151", "Dennis", "All right 5?"),
            this.createToMessage("+49151", "Dennis", "All right 6?"),
            this.createToMessage("+49151", "Dennis", "All right 7?"),
            this.createToMessage("+49151", "Dennis", "All right 8?"),
            this.createToMessage("+49151", "Dennis", "All right 9?"),
            this.createToMessage("+49151", "Dennis", "All right 10?"),
            this.createToMessage("+49151", "Dennis", "All right 11?"),
            this.createToMessage("+49151", "Dennis", "All right 12?"),
            this.createToMessage("+49151", "Dennis", "All right 13?"),
            this.createToMessage("+49151", "Dennis", "All right 14?"),
            this.createToMessage("+49151", "Dennis", "All right 15?"),
            this.createToMessage("+49151", "Dennis", "All right 16?"),
            this.createToMessage("+49151", "Dennis", "All right 17?"),
            this.createToMessage("+49151", "Dennis", "All right 18?"),
            this.createToMessage("+49151", "Dennis", "All right 19?"),
            this.createToMessage("+49151", "Dennis", "All right 20?"),
            this.createToMessage("+49151", "Dennis", "All right 21?"),
            this.createToMessage("+49151", "Dennis", "All right 22?"),
            this.createToMessage("+49151", "Dennis", "All right 23?"),
            this.createToMessage("+49151", "Dennis", "All right 24?"),
            this.createToMessage("+49151", "Dennis", "All right 25?"),
            this.createToMessage("+49151", "Dennis", "All right 26?"),
            this.createToMessage("+49151", "Dennis", "All right 27?"),
            this.createToMessage("+49151", "Dennis", "All right 28?"),
            this.createToMessage("+49151", "Dennis", "All right 29?"),
            this.createToMessage("+49151", "Dennis", "All right 30?"),
            this.createToMessage("+49151", "Dennis", "All right 31?"),
            this.createToMessage("+49151", "Dennis", "All right 32?"),
            this.createToMessage("+49151", "Dennis", "All right 33?"),
            this.createToMessage("+49151", "Dennis", "Jop"),
            this.createToFileMessage("+49151", "Dennis", "Comment image", "image/jpeg", "+49152.jpg", null, null),
            this.createToFileMessage("+49151", "Dennis", null, "video/mp4", "+49153.jpg", null, 16000),
            this.createToFileMessage("+49151", "Dennis", "Comment audio", "audio/3gpp", "VOICE_20200821_170136.m4a", null, 32000),
            this.createToFileMessage("+49151", "Dennis", "Comment document", "application/pdf", "dummy.pdf", 233000, null),
            this.createFromFileMessage("+49151", "Comment image", "image/jpeg", "+49154.jpg", null, null),
            this.createFromFileMessage("+49151", null, "video/mp4", "+49151.jpg", null, 20000),
            this.createFromFileMessage("+49151", "Comment audio", "audio/3gpp", "VOICE_20200821_170136.m4a", null, 32000),
            this.createFromFileMessage("+49151", "Comment document", "application/pdf", "offer.pdf", 233000, null),
            this.createFromFileMessage("+49151", "Comment audio", "audio/3gpp", "VOICE_20200821_170136.m4a", null, 64000),
            this.createFromFileMessage("+49151", null, "image/jpeg", "+49155.jpg", null, null)],
            "+49152": [
                this.createFromMessage("+49152", "Nice"),
                this.createToMessage("+49152", "Nico", "ok")],
            "+49153": [
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Test"),
                this.createFromMessage("+49153", "Nice Hackathon 2019"),
                this.createFromMessage("+49153", "Hey Rolf, are u ready?"),
                this.createToMessage("+49153", "Rolf", "Ready 4 what?"),
                this.createFromMessage("+49153", "For hacking of course!"),
                this.createToMessage("+49153", "Rolf", "But Dennis,\nwe already finished this Hackathon!"),
                this.createFromMessage("+49153", "Oh... you're right :D"),
                this.createFromMessage("+49153", "Wanna show what we did?"),
                this.createToMessage("+49153", "Rolf", "Sure. Let's start!"),
            ],
            "+49154": [
                this.createFromMessage("+49154", "cool"),
                this.createToMessage("+49154", "Nik", "thx")],
            "+49155": [
                this.createFromMessage("+49155", "nett"),
                this.createToMessage("+49155", "Schnappi", "cool loooooooooooooooooooooooooooooooooon teeeeeeeexxxxxxxxxxxt")],
            "+49151-1596225829443": [
                this.createGroupMessage("+49153", "Rolf", false, "Mathemannmannmann"),
                this.createGroupMessage("+49151", "Dennis", true, "nette cat"),
                this.createGroupFileMessage("+49153", "Rolf", false, "Comment document", "application/pdf", "dummy.pdf", 233000, null),
                this.createGroupFileMessage("+49153", "Rolf", false, "comment video", "video/mp4", "+49151.jpg", null, 16000),
                this.createGroupFileMessage("+49153", "Rolf", false, "Comment image", "image/jpeg", "+49155.jpg", null, null),
                this.createGroupFileMessage("+49153", "Rolf", false, "Comment audio", "audio/3gpp", "VOICE_20200821_170136.m4a", null, 32000),
                this.createGroupFileMessage("+49151", "Dennis", true, "Comment image", "image/jpeg", "+49152.jpg", null, null)],
            "+49156": [],
            "+49157": [],
            "+49158": [],
            "+49159": [],
            "+49161": [],
            "+49162": [],
        };

        //this.lastId = 39;
    }

    createToMessage = (fromJid, fromName, text) => {
        return new Message(this.lastId++, false, fromJid, fromName, null, "+49150", text, new Date(), State.receipted, Type.chat, null, null, null)
    }

    createGroupMessage = (fromJid, fromName, isFromMe, text) => {
        return new Message(this.lastId++, isFromMe, fromJid, fromName, fromJid, "+49151-1596225829443", text, new Date(), State.delivered, Type.group, null, null, null, null);
    }

    createGroupFileMessage = (fromJid, fromName, isFromMe, text, mimeType, fileName, fileSize, duration) => {
        return new Message(this.lastId++, isFromMe, fromJid, fromName, fromJid, "+49151-1596225829443", text, new Date(), State.delivered, Type.group, mimeType, fileName, fileSize, duration);
    }

    createToFileMessage = (fromJid, fromName, text, mimeType, fileName, fileSize, duration) => {
        return new Message(this.lastId++, false, fromJid, fromName, null, "+49150", text, new Date(), State.receipted, Type.chat, mimeType, fileName, fileSize, duration)
    }

    createFromFileMessage = (toJid, text, mimeType, fileName, fileSize, duration) => {
        return new Message(this.lastId++, true, "+49150", null, null, toJid, text, new Date(), State.receipted, Type.chat, mimeType, fileName, fileSize, duration)
    }

    createFromMessage = (toJid, text, state) => {
        return new Message(this.lastId++, true, "+49150", null, null, toJid, text, new Date(), state, Type.chat, null, null, null)
    }

    findMessages = (jid, page, max) => {
        // create copy of array.
        let allMessages = [...this.messages[jid]].reverse();
        return allMessages.slice(page * max, page * max + max).reverse();
    }

    sendMessage = (toJid, text) => {
        return this.createFromMessage(toJid, text, State.receipted);
    }

    getLastMessage = (jid) => {
        let lastMessages = this.messages[jid];
        return lastMessages[lastMessages.length - 1];
    }

}