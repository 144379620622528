import React, { useEffect, useRef } from 'react';

const ClickOutsideDetector = ({ children, onClickOutside }) => {
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        handleClick(event);
    };

    const handleHideDropdown = (event) => {
        if (event.key === "Escape") {
            handleClick(event);
        }
    };

    function handleClick (event) {
        if (ref.current && !ref.current.contains(event.target)) {
            onClickOutside();
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleHideDropdown);
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener("keydown", handleHideDropdown);
            document.removeEventListener('click', handleClickOutside);
        };
    });

    return (
        <div ref={ref}>
            {children}
        </div>
    );
};

export default ClickOutsideDetector;