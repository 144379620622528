import axios from 'axios';
import Environment from '../util/Environment';

export default class LoginService {

    login = () => {
        let baseURL = window.location.pathname;
        if (Environment.isElectron()) {
            baseURL = "https://web.voiceapp-messenger.de/";
        }
        let url = baseURL + "api/login";
        return axios.get(url);
    }

}