import React, {Component} from 'react'
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChatItemStyle from './ChatItemStyle';
import MimeType from '../../util/MimeType';
import ProfileImage from '../../util/ProfileImage';
import {withStyles} from '@mui/styles';
import {ListItemButton} from "@mui/material";

class ChatItem extends Component {

    createLastMessageComponent = (chat, classes) => {
        return (
            <>
                {
                    chat.lastMessage && chat.lastMessage.mimeType &&
                    MimeType.getIcon(chat.lastMessage.mimeType)
                }
                {
                    chat.lastMessage &&
                    <Typography variant="body2" component="span" color="textPrimary"
                                className={classes.chatItemText}>
                        {chat.lastMessage.text}
                    </Typography>
                }

            </>)
    }

    render() {
        const {classes, chat, selectedChat, onChatClicked} = this.props;

        return (
            <ListItemButton alignItems="center" onClick={() => onChatClicked(chat)} selected={chat === selectedChat}
                            className={classes.chatItemContainer}>
                <ListItemAvatar>
                    <Avatar src={ProfileImage.getThumb(chat)} className={classes.bigAvatar}/>
                </ListItemAvatar>
                <ListItemText className={classes.chatItemContent}
                              primary={
                                  <>
                                      <Typography variant="body1" component="span" className={classes.chatItemText}>
                                          <Box fontWeight="fontWeightBold">
                                              {chat.name}
                                          </Box>
                                      </Typography>
                                      <Typography variant="caption" className={classes.chatItemText}>
                                          {chat.state}
                                      </Typography>
                                  </>
                              }
                              secondary={
                                  this.createLastMessageComponent(chat, classes)
                              }
                />
            </ListItemButton>
        )
    }
}

export default withStyles(ChatItemStyle)(ChatItem);