const ChatNavDrawerStyle = theme => ({
    drawer: {
        width: theme.drawer.width,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },

    drawerOpen: {
        width: theme.drawer.width,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.drawerClose.width
    },

    toolbar: {
        minHeight: theme.appBar.height,
        '@media (min-width: 0px) and (orientation: landscape)': {
            minHeight: theme.appBar.height
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

});

export default ChatNavDrawerStyle;