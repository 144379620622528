import moment from 'moment';

/*
 * @Author: Dennis Hooth (VoiceApp) 
 * @Date: 2020-10-03 17:45:25 
 * @Last Modified by: Dennis Hooth (VoiceApp)
 * @Last Modified time: 2020-10-29 20:24:13
 */
export default class DateUtil  {

    static formatPlayingTime = (milliSeconds) => {
        return milliSeconds ? moment(milliSeconds).format("mm:ss") : "-";
    }

    static formatSecondsPlayingTime = (milliSeconds) => {
        return milliSeconds ? moment(milliSeconds).format("ss") + "s" : "-";
    }
    
    static formatDate = (date) => {
        var acronym = undefined;
        if (this.isToday(date)) {
            acronym = "heute";
        } else if (this.isYesterday(date)) {
            acronym = "gestern";
        } else if (this.isThisWeek(date)) {
            acronym = moment(date).format('ddd');
        }

        if (acronym) {
            return acronym + " " + moment(date).format('HH:mm');
        }
        return moment(date).format('DD.MM.YY - HH:mm');
    }

    static isToday = date => {
        return moment().isSame(moment(date), 'day')
    }

    static isYesterday = date => {
        return moment().subtract(1, 'day').isSame(moment(date), 'day');
    }

    static isThisWeek = date => {
        return moment().isSame(moment(date), 'week');
    }

}
