import { Component } from 'react';

class Type extends Component {

    /**
     * if the chat or message type is a chat.
     */
    static chat = "CHAT"

    /**
     * if the chat or message type is a group.
     */
    static group = "GROUP"

}

export default Type;