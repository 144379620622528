import {createTheme} from '@mui/material/styles';
import BasicTheme from './BasicTheme';
import lightBackgroundImage from '../../resources/light-background.png';

const LightTheme = createTheme({

    palette: {
        primary: {main: '#69b5c0'},
        secondary: {main: '#69b5c0'},
        mode: 'light',
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        width: "6px",
                    },
                    "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
                        background: "rgba(241,241,241,0)",
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        background: "#b8b8b8",
                        borderRadius: "10px"
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        background: "#8d8d8d",
                    }
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    color: 'white',
                    border: '1px solid #e0e0df',
                },
                track: {
                    color: '#96d0d9',
                },
                rail: {
                    color: '#848282',
                },
            },
        },
    },

    bubbleOut: {
        backgroundColor: '#d4ffc2',
    },

    bubbleIn: {
        backgroundColor: 'white',
    },

    text: {
        color: '#4E4E48',
    },

    button: {
        color: '#69b5c0',
    },

    backgroundImage: `url(${lightBackgroundImage})`,

    paper: {
        backgroundColor: 'white'
    }

}, BasicTheme);

export default LightTheme;