import React, {Component} from 'react'
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChatList from '../chatlist/ChatList';
import ChatNavDrawerStyle from './ChatNavDrawerStyle';
import {withStyles} from '@mui/styles';

class ChatNavDrawer extends Component {

    render() {
        const {open, onClose, theme, classes, chats, selectedChat, onChatClicked} = this.props

        return (
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}>

                <div className={classes.toolbar}>
                    <IconButton onClick={onClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <ChatList chats={chats} selectedChat={selectedChat} onChatClicked={onChatClicked} open={open} />
            </Drawer>
        );
    }
}

export default withStyles(ChatNavDrawerStyle)(ChatNavDrawer);