import React, { Component } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Paper, Grid, Typography, Box } from '@mui/material';
import LoginStyle from './LoginStyle';
import LoginService from '../../service/LoginService';
import Skeleton from '@mui/material/Skeleton';
import Environment from '../../util/Environment';
import VoiceAppQrIcon from "../../resources/voiceapp_app_qr_icon.png";
import { withStyles } from '@mui/styles';

class Login extends Component {

    componentDidMount() {
        if (Environment.isMock()) {
            return;
        }

        const { setUUID } = this.props;
        // rest call uuid
        this.loginService = new LoginService();
        this.loginService.login().then(response => {
            let uuid = response.data;
            setUUID(uuid);
        });
    }

    render() {
        const { uuid, classes, webSocketConnected } = this.props;

        if (!uuid) {
            return <div>Loading</div>
        }

        return (
            <div className={classes.splitContainer}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}>

                    <Grid item container justifyContent="center"
                        alignItems="center">
                        <Paper className={classes.paper}>
                            <Grid container justifyContent="center" alignItems="center">
                                {webSocketConnected ? (<Grid item>
                                    <QRCode value={uuid} logoImage={VoiceAppQrIcon} size="260" logoHeight={50} logoWidth={50} qrStyle="dots"
                                        eyeRadius={[
                                            [10, 10, 10, 10], // top/left eye
                                            [10, 10, 10, 10], // top/right eye
                                            [10, 10, 10, 10], // bottom/left
                                        ]} bgColor="rgba(0, 0, 0, 0)" className={classes.qrCode} />
                                </Grid>) : (<Skeleton variant="rectangular" width={280} height={283} />)}

                                <Grid item>
                                    <Box mt={2}>
                                        <Typography variant="h6" align="center">Scanne den QR-Code, um dich mit deinem Smartphone zu verbinden.</Typography>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box mt={2}>
                                        <a target="blank" href="https://voiceapp-messenger.de">
                                            <img src={require("../../resources/voiceapp_banner.png")} alt="voiceapp_banner.png" style={{ width: "120px" }} />
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item>
                            <a target="blank" href="https://itunes.apple.com/de/app/voiceapp-messenger/id1488999321?mt=8">
                                <img src={require("../../resources/download-on-the-app-store.png")} alt="download-on-the-app-store.png" className={classes.downloadBadge} />
                            </a>
                        </Grid>
                        <Grid item>
                            <a target="blank" href="https://play.google.com/store/apps/details?id=de.voiceapp.messenger&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img src={require("../../resources/google-play-badge.png")} alt="google-play-badge.png" className={classes.downloadBadge} />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(LoginStyle)(Login);