export default class Environment {

    static isMock = () => {
        return process.env.REACT_APP_MOCK === "true";
    }

    static isElectron = () => {
        var userAgent = navigator.userAgent.toLowerCase();
        return (userAgent.indexOf(' electron/') > -1);
    }

}
