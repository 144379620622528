import ScheduleIcon from '@mui/icons-material/Schedule';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import React, { Component } from 'react';

class State extends Component {

    /**
       * if the message was successful delivered to the server or client.
       */
    static delivered = "DELIVERED"

    /**
     * if the message could not delivered.
     */
    static error = "ERROR"

    /**
     * if the message is currently sending.
     */
    static progress = "PROGRESS"

    /**
     * if the message marked as deleted.
     */
    static delete = "DELETE"

    /**
     * if the message was receipted by the user or all group participants.
     */
    static receipted = "RECEIPTED"

    /**
     * if the message was not read.
     */
    static unread = "UNREAD"

    /**
     * if the message was read.
     */
    static read = "READ"

    /**
     * if the message was rejected from server.
     */
    static rejected = "REJECTED"

    /**
     * if the message was send to the server.
     */
    static send = "SEND";

    static getIcon = (state, className) => {
        switch (state) {
            case this.progress:
                return <ScheduleIcon className={className} />;
            case this.delivered:
                return <DoneIcon className={className} />;
            case this.receipted:
                return <DoneAllIcon className={className} />;
            case this.error:
                return <ErrorOutlineIcon className={className} />;
            case this.rejected:
                return <NewReleasesIcon className={className} />;
            default:
        }
    }

}

export default State;