const ChatItemStyle = {

    chatItemContainer: {
        height: 90
    },

    bigAvatar: {
        width: 72,
        height: 72,
        marginLeft: 3
    },

    chatItemText: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 200
    },

    chatItemContent: {
        marginLeft: 22,
        marginRight: 22
    }
};

export default ChatItemStyle;