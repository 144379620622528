import React from 'react';
import DocumentIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import VideocamIcon from '@mui/icons-material/Videocam';

class MimeType {

    static text = "text/plain";
    static html = "text/html";
    static pdf = "application/pdf";
    static word = "application/msword";
    static openWord = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    static powerPoint = "application/mspowerpoint";
    static openPowerPoint = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    static excel = "application/msexcel";
    static openExcel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    static xml = "text/xml";
    static xhtml = "application/xhtml+xml";
    static zip = "application/zip";

    static image = "image";

    static audio = "audio";

    static video = "video";

    static getMediaType = (mimeType) => {
        return mimeType !== null && mimeType !== undefined ? mimeType.split("/")[0] : null;
    }

    static isImage = (mimeType) => {
        return this.getMediaType(mimeType) === this.image;
    }


    static isVideo = (mimeType) => {
        return this.getMediaType(mimeType) === this.video;
    }


    static isAudio = (mimeType) => {
        return this.getMediaType(mimeType) === this.audio;
    }

    static getIcon = (mimeType) => {
        if (this.isImage(mimeType)) {
            return <ImageIcon fontSize="small" style={{ marginRight: '4px' }} />
        } else if (this.isVideo(mimeType)) {
            return <VideocamIcon fontSize="small" style={{ marginRight: '4px' }} />
        } else if (this.isAudio(mimeType)) {
            return <AudiotrackIcon fontSize="small" style={{ marginRight: '4px', marginTop: '-1px' }} />
        }
        return <DocumentIcon fontSize="small" style={{ marginRight: '4px', marginTop: '-1px' }} />
    }

    static getImageName = (mimeType) => {
        var documentName;
        switch (mimeType) {
            case this.powerPoint:
            case this.openPowerPoint:
                this.documentName = "power_point.png";
                break;
            case this.word:
            case this.openWord:
                this.documentName = "word.png";
                break;
            case this.excel:
            case this.openExcel:
                documentName = "excel.png";
                break;
            case this.zip:
                documentName = "zip.png";
                break;
            case this.xml:
            case this.xhtml:
                documentName = "xml.png";
                break;
            case this.pdf:
                documentName = "pdf.png";
                break;
            case this.html:
                documentName = "html.png";
                break;
            default:
                documentName = "document.png";
        }
        return documentName;
    }

}

export default MimeType;