const GroupNameHeaderStyle = theme => ({

    fromPhoneNumber: {
        color: 'gray'
    },

    groupNameContainer: {
        position: 'absolute',
        backgroundColor: "rgba(18,18,18,0.5)",
        top: "6px",
        left: "6px",
        padding: "4px",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: theme.roundedCorner.borderRadius
    },

});

export default GroupNameHeaderStyle;