const DocumentBubbleStyle = theme => ({

    root: {
        width: "350px"
   },

    groupNameContainer: {
        paddingBottom: "10px !important",
        marginBottom: "10px !important",
    },

    documentImage: {
        width: theme.spacing(7),
        height: theme.spacing(7)
    },

    documentLink: {
        cursor: "pointer",
        fontSize: "18px"
    },

});

export default DocumentBubbleStyle;