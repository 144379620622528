import React, { Component } from 'react'
import { Typography, Grid, Box } from '@mui/material';
import GroupNameHeaderStyle from './GroupNameHeaderStyle';
import Type from '../../../model/Type';
import { withStyles } from '@mui/styles';

/*
 * @Author: Dennis Hooth (VoiceApp) 
 * @Date: 2020-10-12 19:55:06 
 * @Last Modified by: Dennis Hooth (VoiceApp)
 * @Last Modified time: 2022-08-05 21:50:47
 */
class GroupNameHeader extends Component {

    createHeader = (classes, fromNameColor, message) => {
        const showFromPhoneNumber = (message.fromPhoneNumber !== null || true) && message.isFromMe;

        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item>
                    <Typography key={message.id} variant="body1" component="span">
                        <Box fontWeight="fontWeightBold" style={{ color: fromNameColor }}>
                            {message.fromName}<br />
                        </Box>
                    </Typography>
                </Grid>
                {showFromPhoneNumber &&
                    <Grid item>
                        <Typography key={message.id} variant="body1" component="span">
                            <Box fontStyle="italic" className={classes.fromPhoneNumber} mr={0.5} ml={1.0}>
                                {message.fromPhoneNumber}<br />
                            </Box>
                        </Typography>
                    </Grid>
                }
            </Grid>
        );
    }

    render() {
        const { message, classes, fromNameColor, showBackground } = this.props;

        if (message.type !== Type.group || message.isFromMe) {
            return (<React.Fragment />);
        }

        let header = this.createHeader(classes, fromNameColor, message);
        if (showBackground) {
            header = <div className={!message.isFromMe ? classes.groupNameContainer : undefined}>
                {header}
            </div>
        }

        return (
            <>
            {header}
            </>
        )
    }
}

export default withStyles(GroupNameHeaderStyle)(GroupNameHeader);