export function sendNotification(title, message, icon) {
    if (!('Notification' in window)) {
        console.log('This browser does not support notifications.');
    } else {
        if (Notification.permission === 'granted') {
            createNotification(title, message, icon)
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    createNotification(title, message, icon)
                }
            });
        }
    }
}

function createNotification(title, message, icon) {
    return new Notification(title, {
        body: message,
        icon: icon
    });
}
