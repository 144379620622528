import React, {Component} from 'react';
import {Box, Grid, TextField} from '@mui/material';
import SendIcon from 'mdi-material-ui/Send';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import InputAdornment from '@mui/material/InputAdornment';
import ChatBarStyle from './ChatBarStyle';
import './ChatBarStyle.css';
import {withStyles} from '@mui/styles';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import LightTheme from '../../assets/theme/LightTheme';
import ClickOutsideDetector from '../common/ClickOutsideDetector';

class ChatBar extends Component {

    state = {
        currentText: "",
        showEmojiKeyboard: false,
        cursorPosition: 0,
        nativeEmoji: undefined
    }

    constructor(props) {
        super(props);
        this.textFieldRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevSelectedChat = prevProps.selectedChat;
        let selectedChat = this.props.selectedChat;

        if (prevSelectedChat && selectedChat && prevSelectedChat.jid !== selectedChat.jid) {
            this.setState({currentText: ""});
        }
    }

    onSend = () => {
        const {onSendMessage} = this.props;
        if (this.state.currentText.length > 0) {
            onSendMessage(this.state.currentText);
            this.setState({currentText: ""});
        }
    }

    openEmojiKeyboard = (event) => {
        this.setState({showEmojiKeyboard: !this.state.showEmojiKeyboard});
        event.stopPropagation();
    }

    closeEmojiKeyboard = () => {
        this.setState({showEmojiKeyboard: false});
    }

    onEmojiSelect = (event) => {
        let {currentText, cursorPosition} = this.state;

        let emoji = event.native;
        if (cursorPosition === 0 && currentText.length > 0) {
            currentText = currentText + emoji;
        } else {
            currentText = currentText.substring(0, cursorPosition) + emoji + currentText.substring(cursorPosition);
        }

        this.setState({currentText: currentText, cursorPosition: cursorPosition + emoji.length});
    }

    onTextChange = (event) => {
        this.setState({currentText: event.target.value});
    }

    onSetCursorPosition = () => {
        this.setState({cursorPosition: this.textFieldRef.current.selectionStart});
    }

    getLocale = () => {
        const userLanguage = window.navigator.userLanguage || window.navigator.language;
        const languageSplit = userLanguage.split("-");
        if (languageSplit.length >= 1) {
            return languageSplit[0];
        }
        return "en";
    }

    getEmojiKeyboardTheme = (theme) => {
        return theme === LightTheme ? "light" : "dark";
    }

    render() {
        const {classes, theme} = this.props;
        const {currentText, showEmojiKeyboard} = this.state;

        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start">

                <Grid item xs={12}>
                    <ClickOutsideDetector onClickOutside={this.closeEmojiKeyboard}>
                        {showEmojiKeyboard &&
                            <Picker data={data} onEmojiSelect={this.onEmojiSelect} locale={this.getLocale()}
                                    theme={this.getEmojiKeyboardTheme(theme)}/>
                        }
                    </ClickOutsideDetector>
                </Grid>

                <Grid item xs={12} style={{width: "100%"}}>
                    <div style={{border: 0}}>
                        <TextField
                            id="outlined-full-width"
                            variant="outlined"
                            className={classes.textArea}
                            placeholder="Nachricht sprechen"
                            inputRef={this.textFieldRef}
                            multiline
                            size='medium'
                            maxRows={5}
                            autoFocus
                            onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                    if (ev.shiftKey) {
                                        this.setState({currentText: this.state.currentText + "\n"});
                                    } else {
                                        this.onSend();
                                    }
                                    ev.preventDefault();
                                }
                            }}

                            InputProps={{
                                className: classes.input,
                                startAdornment: (
                                    <InputAdornment position="start" onClick={this.openEmojiKeyboard}
                                                    className={classes.button}>
                                        <SentimentSatisfiedAltIcon/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Box onClick={this.onSend} className={classes.circle}>
                                            <SendIcon fontSize="medium" className={classes.sendIcon}/>
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                            value={currentText}
                            onChange={this.onTextChange}
                            onSelect={this.onSetCursorPosition}
                        />
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(ChatBarStyle)(ChatBar);