import {createTheme} from '@mui/material/styles';
import BasicTheme from './BasicTheme';
import darkBackgroundImage from '../../resources/dark-background.png';

const DarkTheme = createTheme({

    palette: {
        primary: {main: '#272727'},
        secondary: {main: '#69b5c0'},
        mode: "dark"
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        width: "6px",
                    },
                    "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
                        background: "rgba(241,241,241,0)",
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        background: "#888",
                        borderRadius: "10px"
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    }
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: '#272727',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                label: {
                    color: '#96d0d9',
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    color: 'white',
                    border: '1px solid #e0e0df',
                },
                track: {
                    color: '#00F900',
                },
                rail: {
                    color: '#848282',
                },
            },
        },

        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    background: 'rgba(74,74,74,0.5) !important',
                },
            },
        },

    },

    bubbleOut: {
        backgroundColor: '#69b5c0',
    },

    bubbleIn: {
        backgroundColor: '#272727',
    },

    backgroundImage: `url(${darkBackgroundImage})`,

    paper: {
        backgroundColor: '#424242'
    }

}, BasicTheme);

export default DarkTheme;