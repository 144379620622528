const ImageVideoBubbleStyles = theme => ({

    image: {
        width: window.innerWidth / 6,
        height: window.innerWidth / 6,
        '@media (min-width: 750px)': {
            width: window.innerWidth / 4,
            height: window.innerWidth / 4
        },
        cursor: 'pointer',
    },

    comment: {
        paddingBottom: "0px"
    },

    playIconContainer: {
        position: 'absolute',
        color: 'white',
        bottom: "calc(50% - 50px)",
        left: '50%',
        transform: 'translateX(-50%)',
        cursor: 'pointer'
    },

    playIcon: {
        color: "white",
        width: "100px",
        height: "100px",
        backgroundColor: "rgba(18,18,18,0.5)",
        borderRadius: "50px"
    },

    timeStampFooter: {
        position: "absolute",
        bottom: "6px",
        right: "6px",
        backgroundColor: "rgba(18,18,18,0.5)",
        textAlign: "center",
        borderRadius: theme.roundedCorner.borderRadius,
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "24px"
    },

    durationContainer: {
        position: "absolute",
        bottom: "6px",
        left: "6px",
        backgroundColor: "rgba(18,18,18,0.5)",
        textAlign: "center",
        borderRadius: theme.roundedCorner.borderRadius,
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "24px"
    },

    durationIcon: {
        position: "relative",
        top: "2px",
        color: "white",
        marginRight: "10px",
        fontSize: "20px"
    },

    durationValue: {
        color: "white",
        position: "relative",
        bottom: "4px"
    },

    statusIcon: {
        color: "white",
        fontSize: "20px"
    },

});

export default ImageVideoBubbleStyles;