const AudioBubbleStyle = theme => ({

    root: {
        width: "350px"
    },

    playIcon: {
        fontSize: "60px",
    }
});

export default AudioBubbleStyle;