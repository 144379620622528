import React, {Component} from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Typography} from '@mui/material';
import HeaderBarStyle from './HeaderBarStyle';
import LightTheme from '../../assets/theme/LightTheme';
import {withStyles} from '@mui/styles';

class HeaderBar extends Component {

    render() {
        const {title, classes, open, onOpen, switchTheme, theme} = this.props;

        const themeIcon = theme === LightTheme ? <Brightness4Icon/> : <Brightness7Icon/>;
        return (
            <React.Fragment>
                <CssBaseline/>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >

                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="open chat list"
                            onClick={onOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}>
                            <MenuIcon/>
                        </IconButton>

                        <Typography variant="h6" noWrap className={classes.white}>
                            {title}
                        </Typography>

                        <IconButton edge="end" color="inherit" aria-label="switch light/dark theme"
                                    className={classes.themeButton} onClick={switchTheme}>
                            {themeIcon}
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        )
    }
}

export default withStyles(HeaderBarStyle)(HeaderBar);