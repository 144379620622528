const HeaderBarStyle = theme => ({
    root: {
        display: 'flex'
    },

    appBar: {
        minHeight: theme.appBar.height,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },

    appBarShift: {
        marginLeft: theme.drawer.width,
        width: 'calc(100% - ' + theme.drawer.width + 'px)',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },

    menuButton: {
        marginRight: 30,
        marginLeft: 8,
        color: 'white'
    },

    hide: {
        display: 'none',
    },

    white: {
        color: "white"
    },

    bold: {
        fontWeight: "bold"
    },

    toolbar: {
        minHeight: theme.appBar.height,
        paddingLeft: '24px'
    },

    themeButton: {
        position: "absolute",
        right: theme.spacing(4),
        color: 'white'
    }

});

export default HeaderBarStyle;