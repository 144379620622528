import Chat from '../../model/Chat';
import MessageService from './MessageService';

export default class ChatService {

    messageService = new MessageService();

    findChats = () => {
        return [new Chat("+49151", "Dennis", "I'm the VoiceApper!", this.getLastMessage("+49151"), this.createThumbPath("+49151.jpg")),
        new Chat("+49152", "Nico", "Hi, I'm using VoiceApp", this.getLastMessage("+49152"), this.createThumbPath("+49152.jpg")),
        new Chat("+49153", "Rolf", "Hi, I'm hacking VoiceApp", this.getLastMessage("+49153"), this.createThumbPath("+49153.jpg")),
        new Chat("+49154", "Nik", "Hi, I'm using VoiceApp", this.getLastMessage("+49154"), this.createThumbPath("+49154.jpg")),
        new Chat("+49155", "Schnappi", "Hi, I'm using VoiceApp", this.getLastMessage("+49155"), this.createThumbPath("+49155.jpg")),
        new Chat("+49151-1596225829443", "Cool group", null, this.getLastMessage("+49151-1596225829443"), this.createThumbPath("+49151-1517600833379.jpg")),
        new Chat("+49156", "Kalle", "Scrum unter der Woche und Scrum am Wochenende", null, null),
        new Chat("+49157", "Joe", "Hi, I'm using VoiceApp", null, null),
        new Chat("+49158", "Uwe", "Hi, I'm using VoiceApp", null, null),
        new Chat("+49159", "Jonny", "Hi, I'm using VoiceApp", null, null),
        new Chat("+49161", "Janine", "Hi, I'm using VoiceApp", null, null),
        new Chat("+49162", "Renate", "Hi, I'm using VoiceApp", null, null),
    ]
    }

    getLastMessage = (jid) => {
        return this.messageService.getLastMessage(jid);
    }

    createThumbPath = (fileName) => {
        return require("../../resources/" + fileName);
    }

}