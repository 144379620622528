import React, {Component} from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import ChatItem from './ChatItem';
import clsx from "clsx";
import ChatListStyle from './ChatListStyle';
import {withStyles} from '@mui/styles';

class ChatList extends Component {

    render() {
        const {classes, selectedChat, chats, onChatClicked, open} = this.props;

        return (
            <div
                className={clsx(classes.chatListContainer, {
                    [classes.chatListContainerOpen]: open,
                    [classes.chatListContainerClose]: !open,
                })}>
                <List aria-label="main">
                    {chats.map(chat => (
                        <React.Fragment key={chat.jid}>
                            {open ? (
                                <ChatItem chat={chat} selectedChat={selectedChat} onChatClicked={onChatClicked}/>
                            ) : (
                                <Tooltip title={
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start">
                                        <Typography variant="body1" component="span">
                                            <Box fontWeight="fontWeightBold">
                                                {chat.name}
                                            </Box>
                                        </Typography>
                                        <Typography variant="body1" component="span" className={classes.lastMessageText}>
                                            {chat.lastMessage ? chat.lastMessage.text : null}
                                        </Typography>
                                    </Grid>
                                } placement="right">
                                    <div>
                                        <ChatItem chat={chat} selectedChat={selectedChat}
                                                  onChatClicked={onChatClicked}/>
                                    </div>
                                </Tooltip>
                            )
                            }
                        </React.Fragment>
                    ))}
                </List>
            </div>
        );
    }
}

export default withStyles(ChatListStyle)(ChatList);