const ChatBarStyle = theme => ({

    textArea: {
        width: "100%",
        borderRadius: '20px'
    },

    input: {
        lineHeight: 1.5
    },

    button: {
        cursor: 'pointer',
        color: theme.text.color
    },

    circle: {
        borderRadius: "50%",
        backgroundColor: theme.palette.secondary.main,
        cursor: 'pointer',
        width: 50,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    sendIcon: {
        margin: 'auto',
        color: 'white'
    }

});

export default ChatBarStyle;