const TimeStampFooterStyles = theme => ({
    
    timeStampValueVideo: {
        color: "white", 
        float: "right",
        marginTop: "2px"
    },
    timeStampValue: {
        color: "#848282", 
        float: "right",
    },
    timeStampFooter: {
        float: "right"
    },
    statusIconVideo: {
        color: "white", 
        fontSize: "16px",
        marginLeft: "4px",
        marginTop: "2px"
    },
    statusIcon: {
        color: "#848282", 
        fontSize: "16px",
        marginLeft: "4px",
        marginTop: "2px"
    },

});

export default TimeStampFooterStyles;