const BasicTheme = {

    components: {
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '0'
                }
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '15px',
                },
            },
        },
    },

    appBar: {
        height: 64
    },

    drawer: {
        width: 340
    },

    drawerClose: {
        width: 110
    },

    roundedCorner: {
        borderRadius: '20px'
    },

    text: {
        color: '#4E4E48'
    },

    button: {
        color: '#69b5c0'
    }

};

export default BasicTheme;