import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import Environment from '../util/Environment';

export default class StompManager {

    static instance

    constructor() {
        // SockJS-Client
        var baseURL = window.location.pathname;
        if(Environment.isElectron()) {
            baseURL = "https://web.voiceapp-messenger.de/";
        }
        let url = baseURL + "websocket";
        
        let sock = new SockJS(url);
        this.stompClient = Stomp.over(sock);
        this.isConnected = false;
        this.stompClient.reconnect_delay = 3000;
    }

    static getInstance() {
        if (!StompManager.instance) {
            StompManager.instance = new StompManager();
        }
        return StompManager.instance;
    }

    isConnected() {
        return this.isConnected;
    }

    // WebSocket connect via stompClient
    connect(uuid, connectCallback, errorCallback) {
        const headers = {
            'uuid': uuid,
            'type': "web"
        };
        this.stompClient.connect(headers, () => {
            this.isConnected = true;
            connectCallback();
        }, errorCallback);
    }

    disconnect(disconnectCallback) {
        this.stompClient.disconnect(disconnectCallback);
        this.stompClient.isConnected = false;
        StompManager.instance = undefined;
    }

    subscribe(topic, callback) {
        return this.stompClient.subscribe(topic, callback);
    }

    send(topic, data) {
        this.stompClient.send(topic, data);
    }

}
