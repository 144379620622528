import { useCallback, useEffect, useMemo } from 'react';

export function useFixMissingScroll({ hasMoreItems, fetchMoreItems }) {
    const mainElement = useMemo(() => document.querySelector('main'), []);

    const fetchCb = useCallback(() => {
        fetchMoreItems();
    }, [fetchMoreItems]);

    useEffect(() => {
        const hasScroll = mainElement ? mainElement.scrollHeight > mainElement.clientHeight : false;
        if (!hasScroll && hasMoreItems) {
            setTimeout(() => {
                fetchCb();
            }, 100);
        }
    }, [hasMoreItems, fetchCb, mainElement]);
}