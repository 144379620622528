import React, {Component} from 'react';
import ChatAreaStyle from './ChatAreaStyle';
import clsx from 'clsx';
import ImageVideoBubble from './bubble/ImageVideoBubble';
import TextBubble from './bubble/TextBubble';
import AudioBubble from './bubble/AudioBubble';
import DocumentBubble from './bubble/DocumentBubble';
import MimeType from '../../util/MimeType';
import {Card, CircularProgress, Grid} from '@mui/material';
import ChatBar from './ChatBar';
import SnackbarMessage from '../common/SnackbarMessage';
import randomColor from 'randomcolor';
import Type from '../../model/Type';
import InfiniteScroll from 'react-infinite-scroll-component';
import {scroller} from "react-scroll";
import {withStyles} from "@mui/styles";
import {useFixMissingScroll} from './useFixMissingScroll';

const FixMissingScrollWrapper = ({ hasMoreItems, fetchMoreItems }) => {
    useFixMissingScroll({ hasMoreItems, fetchMoreItems });
    return null;
};

class ChatArea extends Component {

    state = {
        openSnackbar: false,
        snackbarMessage: null,
        fromNameColors: [],
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {scrollToMessageId} = this.props;

        if (scrollToMessageId) {
            this.scrollToPosition(scrollToMessageId);
        }
    }

    onOpenSnackbar = (message) => {
        this.setState({
            openSnackbar: true,
            snackbarMessage: message
        }, () => {
            const {changeScrollToMessageId} = this.props;
            changeScrollToMessageId(message.id);
        });
    }

    onCloseSnackbar = () => {
        this.setState({
            openSnackbar: false,
            snackbarMessage: null
        });
    }

    render() {
        const {
            classes,
            selectedChat,
            messages,
            open,
            hasMoreMessages,
            onLoadMessages,
            onSendMessage,
            theme
        } = this.props;

        return (
            <div className={classes.background}>
                {selectedChat &&
                    <React.Fragment>
                        <FixMissingScrollWrapper hasMoreItems={hasMoreMessages} fetchMoreItems={onLoadMessages} />
                        <div id="chatArea"
                             className={clsx([classes.messageArea], {[classes.open]: open, [classes.closed]: !open})}>
                            <InfiniteScroll
                                dataLength={messages.length}
                                next={onLoadMessages}
                                inverse={true}
                                height={"calc(100vh - 134px)"}
                                hasMore={hasMoreMessages}
                                scrollableTarget="chatArea"
                                loader={<Grid container direction="row" justifyContent="center" alignItems="center"
                                              className={classes.progressContainer}><CircularProgress
                                    className={classes.progress} color={"secondary"}/></Grid>}
                                style={{display: 'flex', flexDirection: 'column-reverse'}}>
                                <Grid container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="stretch"
                                      spacing={0}
                                      className={classes.chatAreaContainer}>
                                    {messages.map(message =>
                                        <Grid key={message.id} name={message.id} item xs={12}
                                              className={classes.bubbleRow}>
                                            <Card className={clsx(classes.bubble, {
                                                [classes.fromMe]: message.isFromMe,
                                                [classes.fromOther]: !message.isFromMe
                                            })}>
                                                {
                                                    this.createBubble(message)
                                                }
                                            </Card>
                                        </Grid>)}
                                </Grid>
                            </InfiniteScroll>
                        </div>

                        <div className={clsx([classes.chatBar], {
                            [classes.chatBarOpen]: open,
                            [classes.chatBarClose]: !open
                        })}>
                            <ChatBar onSendMessage={onSendMessage} theme={theme}/>
                        </div>

                        <SnackbarMessage open={this.state.openSnackbar} message={this.state.snackbarMessage}
                                         severity="error" onClose={this.onCloseSnackbar}/>
                    </React.Fragment>
                }
            </div>
        )
    }

    createBubble = (message) => {
        let fromNameColor = this.getFromNameColor(message);
        let mimeType = message.mimeType;

        if (mimeType === null || mimeType === undefined) {
            return <TextBubble key={message.id} message={message} fromNameColor={fromNameColor}/>;
        }

        switch (MimeType.getMediaType(mimeType)) {
            case "image":
                return <ImageVideoBubble key={message.id} message={message} onOpenSnackbar={this.onOpenSnackbar}
                                         fromNameColor={fromNameColor}/>;
            case "video":
                return <ImageVideoBubble key={message.id} message={message} onOpenSnackbar={this.onOpenSnackbar}
                                         fromNameColor={fromNameColor}/>;
            case "audio":
                return <AudioBubble key={message.id} message={message} onOpenSnackbar={this.onOpenSnackbar}
                                    fromNameColor={fromNameColor}/>;
            default:
                return <DocumentBubble key={message.id} message={message} onOpenSnackbar={this.onOpenSnackbar}
                                       fromNameColor={fromNameColor}/>;
        }
    }

    getFromNameColor = (message) => {
        let fromNameColor = undefined;

        if (message.type === Type.group && !message.isFromMe) {
            const {fromNameColors} = this.state;
            let fromName = message.fromName;
            let currentColor = fromNameColors[fromName]
            if (currentColor === undefined) {
                fromNameColors[fromName] = randomColor();
            }
            fromNameColor = fromNameColors[fromName];
        }
        return fromNameColor;
    }

    scrollToPosition = (messageId) => {
        scroller.scrollTo(messageId, {
            containerId: "chatArea"
        });
    }

}

export default withStyles(ChatAreaStyle)(ChatArea);