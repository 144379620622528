import React, { Component } from 'react';
import { Typography, CardContent, Box } from '@mui/material';
import TimeStampFooter from './TimeStampFooter';
import TextBubbleStyles from './TextBubbleStyles';
import GroupNameHeader from './GroupNameHeader';
import GraphemeSplitter from 'grapheme-splitter';
import { withStyles } from '@mui/styles';


class ChatBubble extends Component {

    isEmojisOnly = (text) => {
        var splitter = new GraphemeSplitter();
        var graphemes = splitter.splitGraphemes(text);

        let emojisOnly = true
        graphemes.forEach(function (grapheme) {
            // text
            if (grapheme.length === 1) {
                emojisOnly = false;
            }
        });
        return emojisOnly;
    }

    countEmojis = (text) => {
        var splitter = new GraphemeSplitter();
        return splitter.countGraphemes(text);
    }

    render() {
        const { message, classes, fromNameColor } = this.props;

        let text = message.text;

        let boxClassName;
        let typographyClassName;

        if (this.isEmojisOnly(text)) {
            boxClassName = classes.center;

            if (this.countEmojis(text) === 1) {
                typographyClassName = classes.singleEmoji;
            } else {
                typographyClassName = classes.multipleEmojis;
            }
        }

        return (
            <CardContent className={classes.root}>
                <GroupNameHeader message={message} fromNameColor={fromNameColor} />
                <Box className={boxClassName}>
                    <Typography key={message.id} variant="body1" component="span" className={typographyClassName}>
                        {text}<br />
                    </Typography>
                </Box>
                <TimeStampFooter message={message} />
            </CardContent>
        )
    }
}

export default withStyles(TextBubbleStyles)(ChatBubble);