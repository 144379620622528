const ChatListStyle = {

    chatListContainer: {
        position: 'relative',
        height: '100%',
        overflowY: 'auto'
    },

    chatListContainerOpen: {
        overflowX: 'auto'
    },

    chatListContainerClose: {
        overflowX: 'hidden'
    },

    lastMessageText: {
        display: 'block',
        textOverflow: 'ellipsis',
        width: 300,
        paddingRight: 12
    },
};
export default ChatListStyle;