export default class Chat {

    constructor(jid, name, state, lastMessage, thumbProfilePicture) {
        this.jid = jid;
        this.name = name;
        this.state = state;
        this.lastMessage = lastMessage;
        this.thumbProfilePicture = thumbProfilePicture;
    }

}