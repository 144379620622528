import React, {Component} from 'react';
import AudioBubbleStyle from './AudioBubbleStyle';
import {Box, CardContent, Grid, Typography} from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import TimeStampFooter from './TimeStampFooter';
import DateUtil from '../../../util/DateUtil';
import GroupNameHeader from './GroupNameHeader';
import {withStyles} from '@mui/styles';

class AudioBubble extends Component {

    state = {
        sliderValue: 0,
        openSnackbar: false
    }

    isVoiceAudioFile = (fileName) => {
        return fileName.match("VOICE_\\d{8}_\\d{6}(%s)?");
    }

    getTitle = (fileName) => {
        // TODO translate.
        return this.isVoiceAudioFile(fileName) ? "Sprachaufnahme" : fileName;
    }

    onChangeSlider = (value) => {
        console.log(value);
    }

    play = () => {
        const {onOpenSnackbar} = this.props;
        // TODO translate.
        onOpenSnackbar("Audio Datei kann noch nicht geöffnet werden.")
    }

    render() {
        const {message, classes, fromNameColor} = this.props;

        return (
            <CardContent className={classes.root}>
                <Box mb={0.5}>
                    <GroupNameHeader message={message} fromNameColor={fromNameColor}/>
                </Box>
                <Grid container direction="row">
                    <Grid container item spacing={1} xs={4}>
                        <IconButton onClick={this.play}>
                            <PlayCircleOutlineIcon className={classes.playIcon}/>
                        </IconButton>
                    </Grid>
                    <Grid container item spacing={1} xs={8}>
                        <Typography variant="body2">{this.getTitle(message.fileName)}</Typography>
                        <Slider value={this.state.sliderValue} onChange={this.onChangeSlider}/>
                        <Typography variant="caption">{DateUtil.formatPlayingTime(message.duration)}</Typography>
                    </Grid>
                </Grid>
                <TimeStampFooter message={message}/>
            </CardContent>
        )
    }
}

export default withStyles(AudioBubbleStyle)(AudioBubble);