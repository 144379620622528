const LoginStyle = theme => ({

    root: {
        minHeight: '100vh',
    },

    splitContainer: {
        height: "50vh",
        backgroundColor: theme.palette.primary.main
    },

    paper: {
        width: "400px !important",
        height: "400px !important",
        padding: "40px !important",
        borderRadius: 25,
        backgroundColor: theme.paper.backgroundColor
    },

    qrCode: {
        width: '280px !important',
        height: '280px !important'
    },

    downloadBadge: {
        maxWidth: "220px",
        margin: "40px"
    }
});

export default LoginStyle;