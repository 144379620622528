import React, {Component} from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

/*
 * @Author: Dennis Hooth (VoiceApp) 
 * @Date: 2020-10-09 01:44:15 
 * @Last Modified by: Dennis Hooth (VoiceApp)
 * @Last Modified time: 2020-10-09 02:23:59
 */
class SnackbarMessage extends Component {

    render() {
        const {open, message, severity, onClose} = this.props;
        return (
            <Snackbar open={open} autoHideDuration={3000} onClose={onClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert elevation={6} variant="filled" severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        )
    }
}

export default SnackbarMessage;